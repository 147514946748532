<template>
  <b-card class="mp-0">
    <!-- Tabs -->

    <b-tabs v-model="tabIndex">
      <b-tab title="Active Users" lazy>
        <div class="alert-body"><ActiveUser /></div>
      </b-tab>
      <b-tab title="Archive Users" lazy>
        <div class="alert-body"><ArchiveUser /></div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

  <script>
import ActiveUser from "../includes/active-user.vue";
import ArchiveUser from "../includes/archive-user.vue";

import { BCard, BTabs, BTab, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Home",
  components: {
    BCard,
    BButton,
    BTabs,
    BTab,
    ActiveUser,
    ArchiveUser,
  },
  directives: {
    Ripple,
  },
};
</script>
</template>