<template>
  <b-sidebar
      id="is-edit-role-sidebar-active"
      :visible="isEditRoleSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @hidden="resetForm"
  >
   <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Edit Roles
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="cancel(); hide()"
          />

        </div>
        <div class="p-2">

          <b-row>
            <b-col  md="12">
                <p>This can be a role a healthcare professional is required to carry out or competency they need to have</p>
            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="Role name"
                  label-for="login-minutes"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Break Minutes"
                    rules="required"
                >
                  <b-form-input
                      id="login-minutes"
                      v-model="defaultItem.roleName"
                      name="login-minutes"
                      max="20"
                      type="text"
                      placeholder=""
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-form-group>
            </b-col>
                <b-col cols="12" md="12">
                  <b-form-group>
                    <label>Which professions</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <v-select
                        v-model="defaultItem.professions"
                        label="text"
                        placeholder="Select Role"
                        multiple
                        :reduce="(roleList) => roleList.value"
                        :options="roleList"
                      />
                      <small v-if="errors[0]" class="text-danger"
                        >The professions field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="mt-2">
                    <p>Role type</p>
                </b-col>
                  <b-col md="6">
                    <b-form-checkbox
                      v-model="defaultItem.type"
                      class="float-left"
                      value="clinical"
                    >
                      Clinical
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="defaultItem.type"
                      class="float-right"
                      value="non-clinical"
                    >
                      Non-clinical
                    </b-form-checkbox>
                  </b-col>
         </b-row>


          <div class="d-flex mt-3">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="defaultItem.professions == '' || defaultItem.roleName == '' || defaultItem.type == '' "
                @click="updateRole()"
            >
              Update
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="shadow text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="cancel(); hide()"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  BFormTextarea,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import settings from '@/apis/modules/settings'
import flatPickr from 'vue-flatpickr-component'
import SettingsAPI from "@/apis/modules/settings";
// import ProgrammesAPI from "@/apis/modules/programmes";


export default {
  name: 'editRoleSidebar',

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCard,

    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BOverlay,
    flatPickr,
    BFormTextarea,

    VBTooltip
  },

  directives: {
    Ripple
  },
  model: {
    prop: 'isEditRoleSidebarActive',
    event: 'update:is-edit-shift-templates-sidebar-active'
  },
  props: {
    isEditRoleSidebarActive: {
      type: Boolean,
      required: true
    },
    selectedData: {
      type: Object,
      required:true
    }
  },


  data () {
    return {

      hide_btn:false,
      roleList:[],
      tableLoading: false,
      defaultItem:{
        professions:undefined,
        roleName:'',
        type:'',
      }
    }
  },
  watch: {
    isEditRoleSidebarActive (val) {
      if (val) this.getJobRoleList()
    },
    selectedData(val) {
      this.defaultItem.professions = val.Professions,
      this.defaultItem.roleName = val.Name,
      this.defaultItem.type = val.Type

      this.defaultItem.professions1 = val.Professions,
      this.defaultItem.roleName1 = val.Name,
      this.defaultItem.type1 = val.Type

    }
  },

  methods: {
    async getJobRoleList() {
      try {
        this.tableLoading = true
        const Response = await SettingsAPI.getJobRoleList();

        this.roleList = Response.data.data.map((x) => ({
          label: x.name,
          value: x.id,
          text:x.name
        }))
        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    resetForm() {

    },

    async updateRole(){
      try {
        this.tableLoading = true
        const response = await settings.editJob(this.selectedData.id, this.defaultItem)
        this.showSuccessMessage('Role Updated Successfully')
        this.$emit('dataRefresh')
        this.tableLoading =false
      }catch (error){
        this.showErrorMessage(`This ${this.defaultItem.roleName} already have with same professions`)
        this.tableLoading =false
        this.defaultItem.professions = this.defaultItem.professions1
        this.defaultItem.roleName= this.defaultItem.roleName1
        this.defaultItem.type= this.defaultItem.type1
        
      }
    },

    cancel(){
      this.defaultItem.professions = undefined
      this.defaultItem.roleName= null
      this.defaultItem.type= null
    },
  },
  mounted () {

  },


}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
#services_table td:nth-child(2) {
  padding-left: 15px !important;
}
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
